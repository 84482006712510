<template>
    <div class="css--admin font-smn antialiased text-white bg-frost-30 relative min-h-screen">
        <div
            v-if="showNums"
            class="flex bg-white bg-opacity-50 justify-between fixed top-0 inset-x-0 px-4 py-2 text-vann z-10"
        >
            <slot name="header" />
        </div>
        <slot />
    </div>
</template>

<script lang="ts" setup>
const route = useRoute();
const showNums = ref(route.query.dev);
</script>
